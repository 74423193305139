<template>
  <div>
    <home_component :key="show_home" />
  </div>
</template>

<script>
import {Client, Config, CheckoutAPI} from '@adyen/api-library'

import home_component from "../components/home_component";
import {mapGetters} from "vuex";

export default {
  name: 'Home',
  components: {home_component},

  computed: {
    ...mapGetters(['show_home'])
  },

  mounted() {

  },

  watch: {
    show_home(value) {
      if(value === false) {
        setTimeout(() => {
          this.$store.dispatch('set_home_show', true)
          this.$bvToast.toast(`An error occurred during the game, your bet is still with you!`, {
            title: 'Error',
            autoHideDelay: 10000,
            variant: 'danger'
          })
        })
      }
    }
  },
}
</script>